import { formatDistanceToNow, parseISO } from 'date-fns';
import { pl } from 'date-fns/locale';

export const getElapsedTime = (dateString: string): string => {
  try {
    return formatDistanceToNow(parseISO(dateString), { 
      addSuffix: true,
      locale: pl 
    });
  } catch (error) {
    return 'data nieznana';
  }
};