import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Pencil, Trash2, Calendar } from 'lucide-react';
import { useAppState } from '../context/AppStateContext';
import { Client } from '../types';
import ConfirmationModal from '../components/ConfirmationModal';
import StatusBadge from '../components/StatusBadge';
import PaymentBadge from '../components/PaymentBadge';
import { format, parseISO } from 'date-fns';
import { pl } from 'date-fns/locale';

export default function ClientDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { clients, appointments, updateClient, deleteClient } = useAppState();
  const client = clients.find(c => c.id === id);
  const clientAppointments = appointments.filter(a => a.clientId === id);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formData, setFormData] = useState<Omit<Client, 'id'> & { 
    surname: string;
    phone: string;
    company: string;
    position: string;
    notes: string;
  }>(
    client ? {
      name: client.name.split(' ')[0],
      surname: client.name.split(' ').slice(1).join(' '),
      email: client.email,
      phone: '',
      company: '',
      position: '',
      rating: client.rating,
      category: client.category,
      notes: ''
    } : {
      name: '',
      surname: '',
      email: '',
      phone: '',
      company: '',
      position: '',
      rating: 5,
      category: 'Standard',
      notes: ''
    }
  );

  if (!client) {
    return <div>Klient nie został znaleziony</div>;
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    updateClient(id!, {
      name: `${formData.name} ${formData.surname}`,
      email: formData.email,
      rating: formData.rating,
      category: formData.category
    });
    setIsEditing(false);
  };

  const handleDelete = () => {
    deleteClient(id!);
    navigate('/clients');
  };

  const formatDate = (dateString: string) => {
    try {
      return format(parseISO(dateString), "d MMMM yyyy 'o' HH:mm", { locale: pl });
    } catch (error) {
      return 'Nieprawidłowa data';
    }
  };

  return (
    <div>
      <button
        onClick={() => navigate('/clients')}
        className="flex items-center text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white mb-6"
      >
        <ArrowLeft className="w-5 h-5 mr-2" />
        Wróć
      </button>

      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">{client.name}</h1>
        </div>
        <div className="flex space-x-4">
          <button
            onClick={() => setShowDeleteModal(true)}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
          >
            <Trash2 className="w-4 h-4 mr-2" />
            Usuń
          </button>
          <button
            onClick={() => setIsEditing(!isEditing)}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gray-800 dark:bg-gray-700 rounded-lg hover:bg-gray-700 dark:hover:bg-gray-600"
          >
            <Pencil className="w-4 h-4 mr-2" />
            Edytuj
          </button>
        </div>
      </div>

      {isEditing ? (
        <form onSubmit={handleSubmit} className="space-y-8">
          <div>
            <h2 className="text-base font-semibold mb-4 text-gray-900 dark:text-white">Klient</h2>
            <p className="text-sm text-gray-500 dark:text-gray-400 mb-6">Klient przypisany do sesji.</p>
            
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Imię</label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Nazwisko</label>
                <input
                  type="text"
                  value={formData.surname}
                  onChange={(e) => setFormData({ ...formData, surname: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6 mt-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">E-mail</label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Numer Telefonu</label>
                <input
                  type="tel"
                  value={formData.phone}
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6 mt-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Firma</label>
                <input
                  type="text"
                  value={formData.company}
                  onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Stanowisko</label>
                <input
                  type="text"
                  value={formData.position}
                  onChange={(e) => setFormData({ ...formData, position: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                />
              </div>
            </div>

            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Notatki</label>
              <textarea
                value={formData.notes}
                onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                rows={4}
              />
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => setIsEditing(false)}
              className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
            >
              Anuluj
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
            >
              Zapisz
            </button>
          </div>
        </form>
      ) : (
        <>
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 mb-8">
            <div className="grid grid-cols-2 gap-6">
              <div>
                <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Imię</h3>
                <p className="mt-1 text-gray-900 dark:text-white">{formData.name}</p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Nazwisko</h3>
                <p className="mt-1 text-gray-900 dark:text-white">{formData.surname}</p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">E-mail</h3>
                <p className="mt-1 text-gray-900 dark:text-white">{client.email}</p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Numer Telefonu</h3>
                <p className="mt-1 text-gray-900 dark:text-white">{formData.phone || '-'}</p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Firma</h3>
                <p className="mt-1 text-gray-900 dark:text-white">{formData.company || '-'}</p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Stanowisko</h3>
                <p className="mt-1 text-gray-900 dark:text-white">{formData.position || '-'}</p>
              </div>
            </div>

            <div className="mt-6">
              <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Notatki</h3>
              <p className="mt-1 text-gray-600 dark:text-gray-300">{formData.notes || '-'}</p>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Sesje klienta</h2>
              <button
                onClick={() => navigate('/appointments/add')}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
              >
                <Calendar className="w-4 h-4 mr-2" />
                Nowa sesja
              </button>
            </div>

            {clientAppointments.length > 0 ? (
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50 dark:bg-gray-700">
                    <tr className="text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                      <th className="px-6 py-3">Data</th>
                      <th className="px-6 py-3">Kategoria</th>
                      <th className="px-6 py-3">Status</th>
                      <th className="px-6 py-3">Płatność</th>
                      <th className="px-6 py-3">Uwagi</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                    {clientAppointments.map((appointment) => (
                      <tr
                        key={appointment.id}
                        onClick={() => navigate(`/appointments/${appointment.id}`)}
                        className="hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer"
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                          {formatDate(appointment.date)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                          {appointment.category}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <StatusBadge status={appointment.status} />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <PaymentBadge status={appointment.paymentStatus} />
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500 dark:text-gray-400">
                          {appointment.comments}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center py-8 text-gray-500 dark:text-gray-400">
                Brak sesji dla tego klienta
              </div>
            )}
          </div>
        </>
      )}

      <ConfirmationModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
        title="Usuń klienta"
        message="Czy na pewno chcesz usunąć tego klienta? Ta operacja jest nieodwracalna."
      />
    </div>
  );
}