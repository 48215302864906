import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Calendar, Users } from 'lucide-react';
import { useAppState } from '../context/AppStateContext';
import { format, addDays, isWithinInterval, startOfToday, parseISO } from 'date-fns';
import { pl } from 'date-fns/locale';
import StatusBadge from '../components/StatusBadge';
import PaymentBadge from '../components/PaymentBadge';
import { getElapsedTime } from '../utils/dateUtils';

export default function Home() {
  const navigate = useNavigate();
  const { appointments, clients } = useAppState();
  const today = startOfToday();
  const twoWeeksFromNow = addDays(today, 14);

  const upcomingAppointments = appointments
    .filter(appointment => {
      try {
        const appointmentDate = parseISO(appointment.date);
        return isWithinInterval(appointmentDate, { start: today, end: twoWeeksFromNow });
      } catch (error) {
        return false;
      }
    })
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .slice(0, 5);

  const recentlyUpdatedAppointments = [...appointments]
    .sort((a, b) => {
      return new Date(a.lastStatusChange).getTime() - new Date(b.lastStatusChange).getTime();
    })
    .slice(0, 5);

  const getClientName = (clientId: string) => {
    const client = clients.find(c => c.id === clientId);
    return client ? client.name : 'Nieznany klient';
  };

  const formatDate = (dateString: string) => {
    try {
      return format(parseISO(dateString), "d MMMM yyyy 'o' HH:mm", { locale: pl });
    } catch (error) {
      return 'Nieprawidłowa data';
    }
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow">
          <div className="p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium text-gray-900 dark:text-white">Nadchodzące sesje</h2>
              <button
                onClick={() => navigate('/appointments/add')}
                className="inline-flex items-center px-3 py-1.5 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              >
                <Plus className="w-4 h-4 mr-1" />
                Nowa sesja
              </button>
            </div>
            <div className="space-y-4">
              {upcomingAppointments.length > 0 ? (
                upcomingAppointments.map(appointment => (
                  <div
                    key={appointment.id}
                    onClick={() => navigate(`/appointments/${appointment.id}`)}
                    className="border dark:border-gray-700 rounded-lg p-4 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer"
                  >
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                          {getClientName(appointment.clientId)}
                        </h3>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          {formatDate(appointment.date)}
                        </p>
                      </div>
                      <div className="flex space-x-2">
                        <StatusBadge status={appointment.status} />
                        <PaymentBadge status={appointment.paymentStatus} />
                      </div>
                    </div>
                    {appointment.comments && (
                      <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">{appointment.comments}</p>
                    )}
                  </div>
                ))
              ) : (
                <p className="text-center text-gray-500 dark:text-gray-400 py-4">Brak nadchodzących sesji</p>
              )}
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow">
          <div className="p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium text-gray-900 dark:text-white">Ostatnio zmienione</h2>
              <button
                onClick={() => navigate('/appointments')}
                className="text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 text-sm font-medium"
              >
                Zobacz wszystkie
              </button>
            </div>
            <div className="space-y-4">
              {recentlyUpdatedAppointments.length > 0 ? (
                recentlyUpdatedAppointments.map(appointment => (
                  <div
                    key={appointment.id}
                    onClick={() => navigate(`/appointments/${appointment.id}`)}
                    className="border dark:border-gray-700 rounded-lg p-4 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer"
                  >
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                          {getClientName(appointment.clientId)}
                        </h3>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          {formatDate(appointment.date)}
                        </p>
                      </div>
                      <div className="flex flex-col items-end space-y-2">
                        <div className="flex space-x-2">
                          <StatusBadge status={appointment.status} />
                          <PaymentBadge status={appointment.paymentStatus} />
                        </div>
                        <span className="text-xs text-gray-500 dark:text-gray-400">
                          Zmiana statusu: {getElapsedTime(appointment.lastStatusChange)}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center text-gray-500 dark:text-gray-400 py-4">Brak sesji</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow">
          <div className="p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium text-gray-900 dark:text-white">Szybkie akcje</h2>
            </div>
            <div className="space-y-2">
              <button
                onClick={() => navigate('/appointments/add')}
                className="w-full text-left px-4 py-2 rounded-md hover:bg-gray-50 dark:hover:bg-gray-700 flex items-center text-blue-600 dark:text-blue-400"
              >
                <Calendar className="h-5 w-5 mr-2" />
                Dodaj nową sesję
              </button>
              <button
                onClick={() => navigate('/clients/add')}
                className="w-full text-left px-4 py-2 rounded-md hover:bg-gray-50 dark:hover:bg-gray-700 flex items-center text-blue-600 dark:text-blue-400"
              >
                <Users className="h-5 w-5 mr-2" />
                Dodaj nowego klienta
              </button>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow">
          <div className="p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium text-gray-900 dark:text-white">Statystyki</h2>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Liczba klientów</p>
                <p className="text-2xl font-semibold text-gray-900 dark:text-white">{clients.length}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Nadchodzące sesje</p>
                <p className="text-2xl font-semibold text-gray-900 dark:text-white">{upcomingAppointments.length}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}