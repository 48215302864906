import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Pencil, Trash2 } from 'lucide-react';
import { useAppState } from '../context/AppStateContext';
import { Appointment } from '../types';
import { format, parseISO } from 'date-fns';
import { pl } from 'date-fns/locale';
import StatusBadge from '../components/StatusBadge';
import PaymentBadge from '../components/PaymentBadge';
import ConfirmationModal from '../components/ConfirmationModal';

export default function AppointmentDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { appointments, clients, updateAppointment, deleteAppointment } = useAppState();
  const appointment = appointments.find(a => a.id === id);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const getInitialFormData = () => {
    if (appointment) {
      try {
        const date = parseISO(appointment.date);
        return {
          clientId: appointment.clientId,
          date: format(date, 'yyyy-MM-dd'),
          time: format(date, 'HH:mm'),
          category: appointment.category,
          status: appointment.status,
          paymentStatus: appointment.paymentStatus,
          comments: appointment.comments
        };
      } catch (error) {
        console.error('Invalid date format:', error);
        return {
          clientId: appointment.clientId,
          date: '',
          time: '',
          category: appointment.category,
          status: appointment.status,
          paymentStatus: appointment.paymentStatus,
          comments: appointment.comments
        };
      }
    }
    return {
      clientId: '',
      date: '',
      time: '',
      category: 'biznes' as Appointment['category'],
      status: 'zaplanowana' as Appointment['status'],
      paymentStatus: 'oczekująca' as Appointment['paymentStatus'],
      comments: ''
    };
  };

  const [formData, setFormData] = useState(getInitialFormData());

  if (!appointment) {
    return <div>Wizyta nie została znaleziona</div>;
  }

  const client = clients.find(c => c.id === appointment.clientId);

  const formatDate = (dateString: string) => {
    try {
      return format(parseISO(dateString), "d MMMM yyyy 'o' HH:mm", { locale: pl });
    } catch (error) {
      return 'Nieprawidłowa data';
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newDate = `${formData.date}T${formData.time}`;
    updateAppointment(id!, {
      ...formData,
      date: newDate
    });
    setIsEditing(false);
  };

  const handleDelete = () => {
    deleteAppointment(id!);
    navigate('/');
  };

  return (
    <div>
      <button
        onClick={() => navigate('/')}
        className="flex items-center text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white mb-6"
      >
        <ArrowLeft className="w-5 h-5 mr-2" />
        Wróć
      </button>

      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">{client?.name}</h1>
          <div className="ml-4 text-gray-500 dark:text-gray-400">
            {formatDate(appointment.date)}
          </div>
        </div>
        <div className="flex space-x-4">
          <button
            onClick={() => setShowDeleteModal(true)}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
          >
            <Trash2 className="w-4 h-4 mr-2" />
            Usuń
          </button>
          <button
            onClick={() => setIsEditing(!isEditing)}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gray-800 dark:bg-gray-700 rounded-lg hover:bg-gray-700 dark:hover:bg-gray-600"
          >
            <Pencil className="w-4 h-4 mr-2" />
            Edytuj
          </button>
        </div>
      </div>

      {isEditing ? (
        <form onSubmit={handleSubmit} className="space-y-8">
          <div>
            <h2 className="text-base font-semibold mb-4 text-gray-900 dark:text-white">Szczegóły sesji</h2>
            <p className="text-sm text-gray-500 dark:text-gray-400 mb-6">Informacje dotyczące sesji.</p>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Klient</label>
              <select
                value={formData.clientId}
                onChange={(e) => setFormData({ ...formData, clientId: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              >
                {clients.map((client) => (
                  <option key={client.id} value={client.id}>{client.name}</option>
                ))}
              </select>
            </div>

            <div className="grid grid-cols-2 gap-6 mt-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Data</label>
                <input
                  type="date"
                  value={formData.date}
                  onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Godzina</label>
                <input
                  type="time"
                  value={formData.time}
                  onChange={(e) => setFormData({ ...formData, time: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                />
              </div>
            </div>

            <div className="grid grid-cols-3 gap-6 mt-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Kategoria</label>
                <select
                  value={formData.category}
                  onChange={(e) => setFormData({ ...formData, category: e.target.value as Appointment['category'] })}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                >
                  <option value="rodzinna">Rodzinna</option>
                  <option value="plener">Plener</option>
                  <option value="prywatna">Prywatna</option>
                  <option value="biznes">Biznes</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Status</label>
                <select
                  value={formData.status}
                  onChange={(e) => setFormData({ ...formData, status: e.target.value as Appointment['status'] })}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                >
                  <option value="zaplanowana">Sesja</option>
                  <option value="w trakcie">Wygładówki</option>
                  <option value="zakończona">Retusz</option>
                  <option value="anulowana">Druk</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Płatność</label>
                <select
                  value={formData.paymentStatus}
                  onChange={(e) => setFormData({ ...formData, paymentStatus: e.target.value as Appointment['paymentStatus'] })}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                >
                  <option value="oczekująca">Niedopłata</option>
                  <option value="opłacona">Opłacona</option>
                  <option value="anulowana">Zadatek</option>
                </select>
              </div>
            </div>

            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Uwagi</label>
              <textarea
                value={formData.comments}
                onChange={(e) => setFormData({ ...formData, comments: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                rows={4}
              />
            </div>
          </div>

          <div>
            <h2 className="text-base font-semibold mb-4 text-gray-900 dark:text-white">Załączniki</h2>
            <p className="text-sm text-gray-500 dark:text-gray-400 mb-6">Lista załączników.</p>

            <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg p-8 text-center">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Click to upload or drag and drop<br />
                SVG, PNG, JPG or GIF (max. 800x400px)
              </p>
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => setIsEditing(false)}
              className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
            >
              Anuluj
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
            >
              Zapisz
            </button>
          </div>
        </form>
      ) : (
        <div className="bg-white dark:bg-gray-800 rounded-lg p-6">
          <div className="grid grid-cols-3 gap-6">
            <div>
              <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Kategoria</h3>
              <p className="mt-1 text-gray-900 dark:text-white">{appointment.category}</p>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Status</h3>
              <div className="mt-1">
                <StatusBadge status={appointment.status} />
              </div>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Płatność</h3>
              <div className="mt-1">
                <PaymentBadge status={appointment.paymentStatus} />
              </div>
            </div>
          </div>

          <div className="mt-6">
            <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Uwagi</h3>
            <p className="mt-1 text-gray-600 dark:text-gray-300">{appointment.comments || '-'}</p>
          </div>

          <div className="mt-6">
            <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">Załączniki</h3>
            <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg p-8 text-center">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Click to upload or drag and drop<br />
                SVG, PNG, JPG or GIF (max. 800x400px)
              </p>
            </div>
          </div>
        </div>
      )}

      <ConfirmationModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
        title="Usuń wizytę"
        message="Czy na pewno chcesz usunąć tę wizytę? Ta operacja jest nieodwracalna."
      />
    </div>
  );
}