import React, { useState } from 'react';
import { Appointment, Client } from '../types';

interface Props {
  clients: Client[];
  onAdd: (appointment: Omit<Appointment, 'id'>) => void;
}

export default function AddAppointmentForm({ clients, onAdd }: Props) {
  const [formData, setFormData] = useState({
    clientId: clients[0]?.id || '',
    date: '',
    time: '',
    category: 'biznes' as Appointment['category'],
    status: 'nowa' as Appointment['status'],
    paymentStatus: 'oczekująca' as Appointment['paymentStatus'],
    comments: ''
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onAdd({
      clientId: formData.clientId,
      date: `${formData.date}T${formData.time}`,
      category: formData.category,
      status: formData.status,
      paymentStatus: formData.paymentStatus,
      comments: formData.comments
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      <div>
        <h2 className="text-base font-semibold mb-4 text-gray-900 dark:text-white">Szczegóły sesji</h2>
        <p className="text-sm text-gray-500 dark:text-gray-400 mb-6">Informacje dotyczące sesji.</p>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Klient</label>
            <select
              value={formData.clientId}
              onChange={(e) => setFormData({ ...formData, clientId: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
            >
              {clients.map((client) => (
                <option key={client.id} value={client.id}>{client.name}</option>
              ))}
            </select>
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Data</label>
              <input
                type="date"
                value={formData.date}
                onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Godzina</label>
              <input
                type="time"
                value={formData.time}
                onChange={(e) => setFormData({ ...formData, time: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              />
            </div>
          </div>

          <div className="grid grid-cols-3 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Kategoria</label>
              <select
                value={formData.category}
                onChange={(e) => setFormData({ ...formData, category: e.target.value as Appointment['category'] })}
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              >
                <option value="rodzinna">Rodzinna</option>
                <option value="plener">Plener</option>
                <option value="prywatna">Prywatna</option>
                <option value="biznes">Biznes</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Status</label>
              <select
                value={formData.status}
                onChange={(e) => setFormData({ ...formData, status: e.target.value as Appointment['status'] })}
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              >
                <option value="nowa">Nowa</option>
                <option value="sesja umówiona">Sesja umówiona</option>
                <option value="sesja wykonana">Sesja wykonana</option>
                <option value="termin wglądówek umówiony">Termin wglądówek umówiony</option>
                <option value="wglądówki wykonane">Wglądówki wykonane</option>
                <option value="zdjęcia wybrane">Zdjęcia wybrane</option>
                <option value="retusz wysłane">Retusz wysłane</option>
                <option value="retusz wykonane">Retusz wykonane</option>
                <option value="retusz do wykonania">Retusz do wykonania</option>
                <option value="wysłane do klienta">Wysłane do klienta</option>
                <option value="wysłane do druku">Wysłane do druku</option>
                <option value="oprawa do wykonania">Oprawa do wykonania</option>
                <option value="odbiór przez klienta">Odbiór przez klienta</option>
                <option value="zakończone">Zakończone</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Płatność</label>
              <select
                value={formData.paymentStatus}
                onChange={(e) => setFormData({ ...formData, paymentStatus: e.target.value as Appointment['paymentStatus'] })}
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              >
                <option value="oczekująca">Oczekująca</option>
                <option value="opłacona">Opłacona</option>
                <option value="anulowana">Anulowana</option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Uwagi</label>
            <textarea
              value={formData.comments}
              onChange={(e) => setFormData({ ...formData, comments: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              rows={4}
              placeholder="Notatki"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end space-x-4">
        <button
          type="submit"
          className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
        >
          Zapisz
        </button>
      </div>
    </form>
  );
}