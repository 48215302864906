import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import ClientDetails from './pages/ClientDetails';
import AppointmentDetails from './pages/AppointmentDetails';
import AddClient from './pages/AddClient';
import AddAppointment from './pages/AddAppointment';
import ClientList from './pages/ClientList';

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/appointments" element={<Dashboard />} />
          <Route path="/clients" element={<ClientList />} />
          <Route path="/clients/add" element={<AddClient />} />
          <Route path="/clients/:id" element={<ClientDetails />} />
          <Route path="/appointments/add" element={<AddAppointment />} />
          <Route path="/appointments/:id" element={<AppointmentDetails />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;