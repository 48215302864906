import React from 'react';
import { Users, Calendar, HelpCircle, User, Home } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import ThemeToggle from './ThemeToggle';

export default function Layout({ children }: { children: React.ReactNode }) {
  const location = useLocation();

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-50 dark:bg-gray-900">
      <div className="w-full md:w-64 bg-white dark:bg-gray-800 border-b md:border-r border-gray-200 dark:border-gray-700 flex flex-col">
        <div className="p-4 md:p-6 flex justify-between items-center">
          <h1 className="text-xl font-bold dark:text-white">client management</h1>
          <ThemeToggle />
        </div>
        
        <nav className="flex md:flex-col px-4 overflow-x-auto md:overflow-x-visible">
          <Link 
            to="/"
            className={`flex items-center px-4 py-2 mb-2 mr-2 md:mr-0 text-gray-700 dark:text-gray-200 rounded-lg whitespace-nowrap ${
              location.pathname === '/' ? 'bg-gray-100 dark:bg-gray-700' : 'hover:bg-gray-50 dark:hover:bg-gray-700'
            }`}
          >
            <Home className="w-5 h-5 mr-3" />
            Panel
          </Link>

          <Link 
            to="/clients"
            className={`flex items-center px-4 py-2 mb-2 mr-2 md:mr-0 text-gray-700 dark:text-gray-200 rounded-lg whitespace-nowrap ${
              location.pathname.includes('/clients') ? 'bg-gray-100 dark:bg-gray-700' : 'hover:bg-gray-50 dark:hover:bg-gray-700'
            }`}
          >
            <Users className="w-5 h-5 mr-3" />
            Klienci
          </Link>
          
          <Link 
            to="/appointments"
            className={`flex items-center px-4 py-2 mb-2 mr-2 md:mr-0 text-gray-700 dark:text-gray-200 rounded-lg whitespace-nowrap ${
              location.pathname === '/appointments' ? 'bg-gray-100 dark:bg-gray-700' : 'hover:bg-gray-50 dark:hover:bg-gray-700'
            }`}
          >
            <Calendar className="w-5 h-5 mr-3" />
            Sesje
          </Link>
        </nav>

        <div className="hidden md:block p-4 border-t border-gray-200 dark:border-gray-700">
          <Link 
            to="/help"
            className="flex items-center px-4 py-2 text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-lg"
          >
            <HelpCircle className="w-5 h-5 mr-3" />
            Pomoc
          </Link>
          
          <div className="flex items-center px-4 py-2 mt-2 text-gray-700 dark:text-gray-200">
            <User className="w-5 h-5 mr-3" />
            Adam Mikołajczyk
          </div>
        </div>
      </div>

      <div className="flex-1 overflow-auto pt-4 md:pt-0">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          {children}
        </div>
      </div>
    </div>
  );
}