import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { useAppState } from '../context/AppStateContext';
import AddAppointmentForm from '../components/AddAppointmentForm';

export default function AddAppointment() {
  const navigate = useNavigate();
  const { addAppointment, clients } = useAppState();

  const handleAddAppointment = (appointmentData: any) => {
    addAppointment(appointmentData);
    navigate('/');
  };

  return (
    <div>
      <button
        onClick={() => navigate('/')}
        className="mb-4 flex items-center text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
      >
        <ArrowLeft className="w-4 h-4 mr-2" />
        Powrót
      </button>
      <h2 className="text-2xl font-bold mb-6 text-gray-900 dark:text-white">Dodaj Nową Wizytę</h2>
      <AddAppointmentForm clients={clients} onAdd={handleAddAppointment} />
    </div>
  );
}