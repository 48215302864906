import { Client, Appointment } from '../types';
import { addDays, subDays, addHours, subHours } from 'date-fns';

const today = new Date();

export const initialClients: Client[] = [
  {
    id: '1',
    name: 'Anna Kowalska',
    email: 'anna@example.com',
    rating: 5,
    category: 'Premium',
    attachments: []
  },
  {
    id: '2',
    name: 'Jan Nowak',
    email: 'jan@example.com',
    rating: 4,
    category: 'Standard',
    attachments: []
  },
  {
    id: '3',
    name: 'Maria Wiśniewska',
    email: 'maria@example.com',
    rating: 5,
    category: 'VIP',
    attachments: []
  },
  {
    id: '4',
    name: 'Piotr Zieliński',
    email: 'piotr@example.com',
    rating: 3,
    category: 'Standard',
    attachments: []
  },
  {
    id: '5',
    name: 'Katarzyna Dąbrowska',
    email: 'katarzyna@example.com',
    rating: 5,
    category: 'Premium',
    attachments: []
  },
  {
    id: '6',
    name: 'Tomasz Lewandowski',
    email: 'tomasz@example.com',
    rating: 4,
    category: 'Standard',
    attachments: []
  },
  {
    id: '7',
    name: 'Agnieszka Wójcik',
    email: 'agnieszka@example.com',
    rating: 5,
    category: 'VIP',
    attachments: []
  },
  {
    id: '8',
    name: 'Marcin Kamiński',
    email: 'marcin@example.com',
    rating: 4,
    category: 'Premium',
    attachments: []
  },
  {
    id: '9',
    name: 'Karolina Szymańska',
    email: 'karolina@example.com',
    rating: 5,
    category: 'VIP',
    attachments: []
  },
  {
    id: '10',
    name: 'Robert Wojciechowski',
    email: 'robert@example.com',
    rating: 4,
    category: 'Standard',
    attachments: []
  },
  {
    id: '11',
    name: 'Magdalena Kaczmarek',
    email: 'magdalena@example.com',
    rating: 5,
    category: 'Premium',
    attachments: []
  },
  {
    id: '12',
    name: 'Krzysztof Pawlak',
    email: 'krzysztof@example.com',
    rating: 3,
    category: 'Standard',
    attachments: []
  }
];

export const initialAppointments: Appointment[] = [
  {
    id: '1',
    clientId: '1',
    date: addDays(today, 2).toISOString(),
    category: 'rodzinna',
    status: 'nowa',
    paymentStatus: 'oczekująca',
    comments: 'Sesja rodzinna w plenerze',
    lastStatusChange: subHours(today, 2).toISOString(),
    attachments: []
  },
  {
    id: '2',
    clientId: '2',
    date: addDays(today, 4).toISOString(),
    category: 'biznes',
    status: 'sesja umówiona',
    paymentStatus: 'opłacona',
    comments: 'Zdjęcia do CV i LinkedIn',
    lastStatusChange: subDays(today, 1).toISOString(),
    attachments: []
  },
  {
    id: '3',
    clientId: '3',
    date: addDays(today, 1).toISOString(),
    category: 'plener',
    status: 'sesja wykonana',
    paymentStatus: 'opłacona',
    comments: 'Sesja narzeczeńska w parku',
    lastStatusChange: subDays(today, 3).toISOString(),
    attachments: []
  },
  {
    id: '4',
    clientId: '4',
    date: addDays(today, 7).toISOString(),
    category: 'prywatna',
    status: 'termin wglądówek umówiony',
    paymentStatus: 'oczekująca',
    comments: 'Sesja portretowa w studio',
    lastStatusChange: subDays(today, 5).toISOString(),
    attachments: []
  },
  {
    id: '5',
    clientId: '5',
    date: subDays(today, 2).toISOString(),
    category: 'biznes',
    status: 'wglądówki wykonane',
    paymentStatus: 'opłacona',
    comments: 'Zdjęcia biznesowe dla zespołu',
    lastStatusChange: subHours(today, 12).toISOString(),
    attachments: []
  },
  {
    id: '6',
    clientId: '6',
    date: subDays(today, 5).toISOString(),
    category: 'rodzinna',
    status: 'zdjęcia wybrane',
    paymentStatus: 'opłacona',
    comments: 'Sesja świąteczna',
    lastStatusChange: subDays(today, 2).toISOString(),
    attachments: []
  },
  {
    id: '7',
    clientId: '7',
    date: subDays(today, 10).toISOString(),
    category: 'plener',
    status: 'retusz wysłane',
    paymentStatus: 'opłacona',
    comments: 'Sesja w górach',
    lastStatusChange: subHours(today, 36).toISOString(),
    attachments: []
  },
  {
    id: '8',
    clientId: '8',
    date: addDays(today, 3).toISOString(),
    category: 'prywatna',
    status: 'retusz wykonane',
    paymentStatus: 'oczekująca',
    comments: 'Sesja studyjna',
    lastStatusChange: subDays(today, 4).toISOString(),
    attachments: []
  },
  {
    id: '9',
    clientId: '9',
    date: subDays(today, 15).toISOString(),
    category: 'biznes',
    status: 'retusz do wykonania',
    paymentStatus: 'opłacona',
    comments: 'Zdjęcia korporacyjne',
    lastStatusChange: subDays(today, 1).toISOString(),
    attachments: []
  },
  {
    id: '10',
    clientId: '10',
    date: subDays(today, 20).toISOString(),
    category: 'rodzinna',
    status: 'wysłane do klienta',
    paymentStatus: 'opłacona',
    comments: 'Album rodzinny',
    lastStatusChange: subHours(today, 6).toISOString(),
    attachments: []
  },
  {
    id: '11',
    clientId: '11',
    date: addDays(today, 5).toISOString(),
    category: 'plener',
    status: 'wysłane do druku',
    paymentStatus: 'opłacona',
    comments: 'Sesja w parku',
    lastStatusChange: subDays(today, 7).toISOString(),
    attachments: []
  },
  {
    id: '12',
    clientId: '12',
    date: subDays(today, 25).toISOString(),
    category: 'prywatna',
    status: 'oprawa do wykonania',
    paymentStatus: 'opłacona',
    comments: 'Sesja portretowa',
    lastStatusChange: subHours(today, 24).toISOString(),
    attachments: []
  },
  {
    id: '13',
    clientId: '1',
    date: subDays(today, 30).toISOString(),
    category: 'biznes',
    status: 'odbiór przez klienta',
    paymentStatus: 'opłacona',
    comments: 'Zdjęcia do katalogu',
    lastStatusChange: subDays(today, 10).toISOString(),
    attachments: []
  },
  {
    id: '14',
    clientId: '2',
    date: addDays(today, 6).toISOString(),
    category: 'plener',
    status: 'zakończone',
    paymentStatus: 'opłacona',
    comments: 'Sesja plenerowa w ogrodzie',
    lastStatusChange: subDays(today, 15).toISOString(),
    attachments: []
  },
  {
    id: '15',
    clientId: '3',
    date: addDays(today, 8).toISOString(),
    category: 'rodzinna',
    status: 'nowa',
    paymentStatus: 'oczekująca',
    comments: 'Sesja rodzinna na plaży',
    lastStatusChange: subHours(today, 1).toISOString(),
    attachments: []
  }
];