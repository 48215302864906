import React from 'react';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { pl } from 'date-fns/locale';
import { Appointment, Client } from '../types';
import StatusBadge from './StatusBadge';
import PaymentBadge from './PaymentBadge';

interface Props {
  appointments: Appointment[];
  clients: Client[];
  onDelete: (id: string) => void;
}

export default function AppointmentList({ appointments, clients, onDelete }: Props) {
  const navigate = useNavigate();

  const getClientName = (clientId: string) => {
    const client = clients.find(c => c.id === clientId);
    return client ? client.name : 'Nieznany klient';
  };

  const formatDate = (dateString: string) => {
    try {
      return format(parseISO(dateString), "d MMMM yyyy HH:mm", { locale: pl });
    } catch (error) {
      return 'Nieprawidłowa data';
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg">
      <div className="hidden md:block">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead>
            <tr className="text-left text-sm text-gray-500 dark:text-gray-400">
              <th className="px-6 py-3">Klient</th>
              <th className="px-6 py-3">Termin</th>
              <th className="px-6 py-3">Kategoria</th>
              <th className="px-6 py-3">Status</th>
              <th className="px-6 py-3">Upłynęło</th>
              <th className="px-6 py-3">Płatność</th>
              <th className="px-6 py-3">Uwagi</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            {appointments.map((appointment) => (
              <tr 
                key={appointment.id}
                onClick={() => navigate(`/appointments/${appointment.id}`)}
                className="hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer"
              >
                <td className="px-6 py-4 text-gray-900 dark:text-gray-100">
                  {getClientName(appointment.clientId)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-gray-900 dark:text-gray-100">
                  {formatDate(appointment.date)}
                </td>
                <td className="px-6 py-4 text-gray-900 dark:text-gray-100">
                  {appointment.category}
                </td>
                <td className="px-6 py-4">
                  <StatusBadge status={appointment.status} />
                </td>
                <td className="px-6 py-4 text-gray-500 dark:text-gray-400">3 dni</td>
                <td className="px-6 py-4">
                  <PaymentBadge status={appointment.paymentStatus} />
                </td>
                <td className="px-6 py-4 text-gray-500 dark:text-gray-400">
                  {appointment.comments}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      <div className="md:hidden space-y-4 p-4">
          {appointments.map((appointment) => (
            <div
              key={appointment.id}
              onClick={() => navigate(`/appointments/${appointment.id}`)}
              className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg cursor-pointer"
            >
              <div className="font-medium text-gray-900 dark:text-gray-100 mb-2">
                {getClientName(appointment.clientId)}
              </div>
              <div className="text-sm text-gray-900 dark:text-gray-100 mb-2">
                {formatDate(appointment.date)}
              </div>
              <div className="flex flex-wrap gap-2 mb-2">
                <span className="text-sm text-gray-600 dark:text-gray-300">
                  {appointment.category}
                </span>
                <StatusBadge status={appointment.status} />
                <PaymentBadge status={appointment.paymentStatus} />
              </div>
              {appointment.comments && (
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  {appointment.comments}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}