import React, { createContext, useContext, useState } from 'react';
import { Client, Appointment } from '../types';
import { initialClients, initialAppointments } from '../data/initialData';

interface AppStateContextType {
  clients: Client[];
  appointments: Appointment[];
  addClient: (client: Omit<Client, 'id'>) => void;
  updateClient: (id: string, client: Omit<Client, 'id'>) => void;
  deleteClient: (id: string) => void;
  addAppointment: (appointment: Omit<Appointment, 'id'>) => void;
  updateAppointment: (id: string, appointment: Omit<Appointment, 'id'>) => void;
  deleteAppointment: (id: string) => void;
}

const AppStateContext = createContext<AppStateContextType | undefined>(undefined);

export function AppStateProvider({ children }: { children: React.ReactNode }) {
  const [clients, setClients] = useState<Client[]>(initialClients);
  const [appointments, setAppointments] = useState<Appointment[]>(initialAppointments);

  const addClient = (clientData: Omit<Client, 'id'>) => {
    const client = {
      ...clientData,
      id: Math.random().toString(36).substr(2, 9),
      attachments: []
    };
    setClients([...clients, client]);
  };

  const updateClient = (id: string, clientData: Omit<Client, 'id'>) => {
    setClients(clients.map(client => {
      if (client.id === id) {
        return {
          ...clientData,
          id,
          attachments: client.attachments || []
        };
      }
      return client;
    }));
  };

  const deleteClient = (id: string) => {
    setClients(clients.filter(client => client.id !== id));
    setAppointments(appointments.filter(appointment => appointment.clientId !== id));
  };

  const addAppointment = (appointmentData: Omit<Appointment, 'id'>) => {
    const appointment = {
      ...appointmentData,
      id: Math.random().toString(36).substr(2, 9),
      lastStatusChange: new Date().toISOString(),
      attachments: []
    };
    setAppointments([...appointments, appointment]);
  };

  const updateAppointment = (id: string, appointmentData: Omit<Appointment, 'id'>) => {
    setAppointments(appointments.map(appointment => {
      if (appointment.id === id) {
        const lastStatusChange = appointment.status !== appointmentData.status
          ? new Date().toISOString()
          : appointment.lastStatusChange;
        
        return {
          ...appointmentData,
          id,
          lastStatusChange,
          attachments: appointment.attachments || []
        };
      }
      return appointment;
    }));
  };

  const deleteAppointment = (id: string) => {
    setAppointments(appointments.filter(appointment => appointment.id !== id));
  };

  return (
    <AppStateContext.Provider value={{
      clients,
      appointments,
      addClient,
      updateClient,
      deleteClient,
      addAppointment,
      updateAppointment,
      deleteAppointment,
    }}>
      {children}
    </AppStateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within an AppStateProvider');
  }
  return context;
}